function activateItem(item, collection, activeClass) {
    collection.forEach(element => {
        if (element !== item) element.classList.remove(activeClass)
    })
    item.classList.toggle(activeClass)
}

function deactivateItem(collection, className) {
    collection.forEach(element => {
        element.classList.remove(className)
    })
}

let headerMenu = document.querySelectorAll(".fp-header__left-menu-link-arrow")
let dropdownCollection = document.querySelectorAll(".fp-header__dropdown")

for (let i = 0; i < headerMenu.length; i++) {
    let item = headerMenu[i]

    item.addEventListener("click", e => {
        if (window.innerWidth > 640) {
            e.preventDefault()
            activateItem(item, headerMenu, "fp-header__left-menu-link-active")
            toggleDropdown(item, dropdownCollection)
        } else {
            e.preventDefault()
            toggleMobileDropdown(item)
        }
    })
}

function toggleMobileDropdown(item) {
    let mobileDropdonw = document.querySelector(".fp-header__mobile-menu")
    let menuParent = item.parentElement
    mobileDropdonw.classList.toggle("open-menu")
    menuParent.classList.toggle("active-menu")
}

/*document.querySelector(".landing-localization-switcher").addEventListener("click", function () {
    let dropdownCollection = document.querySelectorAll(".fp-header__dropdown")
    let topMenu = document.querySelectorAll(".fp-header__left-menu-link-arrow")
    let subdropdowns = document.querySelectorAll(".hamburger-mobile__sub-dropdown")
    deactivateItem(dropdownCollection, "fp-header__dropdown-show")
    deactivateItem(subdropdowns, "hamburger-mobile__sub-dropdown-show")
    deactivateItem(topMenu, "fp-header__left-menu-link-active")
    // addBlur()
})*/

let hamburgerMenu = document.querySelector(".hamburger-mobile")
if (hamburgerMenu) {
    hamburgerMenu.addEventListener("click", function (e) {
        e.stopPropagation()
        document.querySelector(".fp-header__mobile-menu").classList.toggle("show")
        hamburgerMenu.classList.toggle("open")
        //  document.body.classList.toggle("blur")
        document.querySelector(".fp__overlay").classList.toggle("fp__overlay-show")
    })
}

function toggleDropdown(item, dropdownCollection) {
    let hamburger = document.querySelectorAll(".hamburger__dropdown")

    deactivateItem(hamburger, "hamburger__dropdown-show")
    deactivateItem(dropdownCollection, "fp-header__dropdown-show")

    let dropdown = item.nextElementSibling
    if (item.classList.contains("fp-header__left-menu-link-active")) {
        dropdown.classList.add("fp-header__dropdown-show")
        addBlur()
    } else {
        dropdown.classList.remove("fp-header__dropdown-show")
        removeBlur()
    }
}

function addBlur() {
    //  document.body.classList.add("blur")
    document.querySelector(".fp__overlay").classList.add("fp__overlay-show")
    // document.querySelector(".fp-banner").classList.add("fp-banner-blur")
    // document.querySelector(".fp-join").classList.add("fp-banner-blur")
    // document.querySelector(".fp-join2").classList.add("fp-banner-blur")
    // document.querySelector(".fp-testimonials").classList.add("fp-banner-blur")
    // document.querySelector(".fp-why").classList.add("fp-banner-blur")
    // document.querySelector(".fb-contact-us").classList.add("fp-banner-blur")
    // document.querySelector(".fp-footer").classList.add("fp-banner-blur")
}

function removeBlur() {
    //  document.body.classList.remove("blur")
    document.querySelector(".fp__overlay").classList.remove("fp__overlay-show")
    // document.querySelector(".fp-banner").classList.remove("fp-banner-blur")
    // document.querySelector(".fp-join").classList.remove("fp-banner-blur")
    // document.querySelector(".fp-join2").classList.remove("fp-banner-blur")
    // document.querySelector(".fp-testimonials").classList.remove("fp-banner-blur")
    // document.querySelector(".fp-why").classList.remove("fp-banner-blur")
    // document.querySelector(".fb-contact-us").classList.remove("fp-banner-blur")
    // document.querySelector(".fp-footer").classList.remove("fp-banner-blur")
}

function closePopUps() {
    let hamburger = document.querySelectorAll(".hamburger__dropdown")
    let hamburgerMobile = document.querySelectorAll(".hamburger-mobile__dropdown")
    let dropdownCollection = document.querySelectorAll(".fp-header__dropdown")
    let topMenu = document.querySelectorAll(".fp-header__left-menu-link-arrow")
    let subdropdowns = document.querySelectorAll(".hamburger-mobile__sub-dropdown")
    let overlay = document.querySelector(".fp__overlay")
    if (!overlay) {
        return
    }
    overlay.addEventListener("click", function () {
        deactivateItem(hamburger, "hamburger__dropdown-show")
        deactivateItem(hamburgerMobile, "hamburger-mobile__dropdown-show")
        deactivateItem(dropdownCollection, "fp-header__dropdown-show")
        deactivateItem(subdropdowns, "hamburger-mobile__sub-dropdown-show")
        deactivateItem(topMenu, "fp-header__left-menu-link-active")
        document.querySelector(".fp-header__mobile-menu").classList.remove("show")
        document.querySelector(".hamburger-mobile").classList.remove("open")
        removeBlur()
    })
}

closePopUps()

window.scrollToView = function (target, selector) {
    const element = document.querySelector(selector)
    const header = document.querySelector("header")
    const y = element.getBoundingClientRect().top + window.scrollY - header.offsetHeight
    window.scrollTo({ top: y, behavior: "smooth" })
}
